// Job Descriptio page
export const experienceLevelOption = [
    "Entry (0-2 years of experience)",
    "Medium (3-5 years of experience)",
    "Senior (6+ years of experience)"
];

export const interviewOptions = [
    { value: "hr-round", label: "HR Round" },
    { value: "prod-sense", label: "Product Sense" }
  ];

export const languageOptions = [
    { value: "en", label: "English" },
    { value: "zh", label: "中文（简体）" },
    { value: "zh-TW", label: "中文（繁體）"}
  ];


// Additional Information page

export const add_info_instr = "The text entered here will help target role and skill fit."

export const add_info_instr_cont = "Most candidates ask recruiters the same questions despite having read a job description. Pre-answering these questions for the AI will significantly improve candidate quality as they move through the hiring lifecycle:"

export const add_info_common_questions = [
    "What is the product that this person will be working on?",
    "Is there a specific role already designated for the person you will hire?",
    "Is this a new or existing role?",
    "How big is the team?",
    "What is the hiring process?",
    "What are the team/organizational goals for the next 3-5 years?"
]


// AI Screening page

export const ai_scn_instr = "Please select your preferred interview questions. We will ask these on your behalf to save your time during the phone call. Our interview includes, but is not limited to, your selected questions."

export const defaultQuestions = [
    "Can you describe how your experience and skills make you a suitable candidate for this role?",
    "Can you discuss any academic accolades or projects that are particularly relevant to this role?",
    "Can you provide an example of a complex challenge you have faced in the past and how you handled it?",
    "Can you share a notable achievement in your career that you believe highlights your capability to excel in this role?",
    "How do you learn new skills?",
    "What is your biggest failure?",
    "How would you rate yourself on these three traits? And why?"
];


// Evaluation Criterion page

export const eval_instr = "Please select the criterion for evaluating the candidate. We will rate these on a scale from 0 (does not meet qualifications) to 5 (exceeds qualifications)."

export const criteria = [
    'Culture Fit',
    'Collaboration',
    'Qualification',
    'User Empathy',
    'Analytical',
    'Relevance experience',
    'Logic Thinking',
    'Business Acumen',
    'Communication',
    'Strategy',
    'Enthusiasm',
    'Passion and creativity'
];
