import axios from "axios";
import { Box, Button, FixedZIndex, Text} from "gestalt"; 
import React, { useState, useEffect } from "react";
import { SideButton } from "../components/JobDescriptions/SideButton";
import { JobDescription } from "../components/JobDescriptions/JobDescription";
import { AdditionalInformation } from "../components/JobDescriptions/AdditionalInformation";
import { QuestionList } from "../components/JobDescriptions/QuestionList";
import { EvaluationCriterion } from "../components/JobDescriptions/EvaluationCriterion";
import { Review } from "../components/JobDescriptions/Review";
import { getUser } from "../components/getUser";
import { InvitationPage } from "../components/JobDescriptions/InvitationPage";
import { Header } from "../components/Header";
import { useUser } from '@clerk/clerk-react';


const App = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [jobTitle, setJobTitle] = useState("");
    const [jobID, setJobID] = useState("")
    const [jobDescription, setJobDescription] = useState("");
    const [experienceLevel, setExperienceLevel] = useState("");
    const [completedSteps, setCompletedSteps] = useState([]);
    const [additionalInformation, setAdditionalInformation] = useState("");
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [evaluationCriterion, setEvaluationCriterion] = useState([]);
    const [userData, setUserData] = useState(null);
    const [jobInterviewLink, setJobInterviewLink] = useState("");
    const [collapsed, setCollapsed] = useState(false);
    const [interviewRound, setInterviewRound] = useState({ value: '', label: '' });
    const [errorMessage, setErrorMessage] = useState("");
    const [language, setLanguage] = useState({ value: '', label: ''});
    const [isFormComplete, setIsFormComplete] = useState(false);

    const b2cUrl = process.env.REACT_APP_TOC_URL + "/interview?job="
    const stepComponents = [
        <JobDescription
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          jobDescription={jobDescription}
          setJobDescription={setJobDescription}
          experienceLevel={experienceLevel}
          setExperienceLevel={setExperienceLevel}  
          interviewRound={interviewRound}
          setInterviewRound={setInterviewRound}
          language={language}
          setLanguage={setLanguage}
        />,

        <AdditionalInformation
          additionalInformation={additionalInformation}
          setAdditionalInformation={setAdditionalInformation}
        />,

        <QuestionList
          interviewQuestions={interviewQuestions}
          setInterviewQuestions={setInterviewQuestions}
        />,

        <EvaluationCriterion
          evaluationCriterion={evaluationCriterion}
          setEvaluationCriterion={setEvaluationCriterion}
        />,

        <Review 
          jobTitle={jobTitle}
          jobDescription={jobDescription}
          experienceLevel={experienceLevel}
          additionalInformation={additionalInformation}
          interviewQuestions={interviewQuestions}
          evaluationCriterion={evaluationCriterion}
          interviewRound={interviewRound}
          language={language}
        />,
        
        <InvitationPage
          jobInterviewLink={jobInterviewLink}
        />
    ];
    const { isSignedIn, user, isLoaded } = useUser();

    useEffect(
      () => {
          const fetchUserData = async () => {
              if (isLoaded){
                let curUser = {emailAddress:user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName}
                  let userInfo = await getUser(isSignedIn, curUser);
                  setUserData(userInfo);
              }
          };
          fetchUserData();
      }, [isSignedIn, user, isLoaded]); // Empty dependency array means this effect runs once after the initial render

    useEffect(() => {
        const isComplete = jobTitle && experienceLevel && interviewRound.value && language.value && jobDescription;
        setIsFormComplete(isComplete);
    }, [jobTitle, experienceLevel, interviewRound, language, jobDescription]);

    const handleSubmit = async () => {
        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/bData/jobCreation";
        const formatInterviewQuestions = interviewQuestions.map(q => q.content);
        try {
            const response = await axios.post(apiUrl, {
            jobTitle: jobTitle,
            jobDescription: jobDescription,
            experienceLevel: experienceLevel,
            language: language.value,
            additionalInformation: additionalInformation,
            interviewQuestions: formatInterviewQuestions,
            evaluationCriterion: evaluationCriterion,
            interviewRound: interviewRound.value,
            recruiterID: userData.id,
            b2cUrl: b2cUrl
            });

            const responseData = response.data;
            if (responseData.status === "success") {
                console.log("Job created with ID:", responseData.data.jobID);
                setJobID(responseData.data.jobID);
                setJobInterviewLink(b2cUrl+responseData.data.jobID+"&round="+interviewRound.value+"&language="+language.value)
                handleNextStep();
            } else if (responseData.status === "limit_reached") {
                // Handle limit reached response
                setErrorMessage(responseData.message);
            } else {
                // Handle other unexpected statuses
                setErrorMessage("An unexpected error occurred during job creation.");
            }
        } catch (error) {
            console.error("Error submitting job creation data:", error);
            setErrorMessage("An error occurred during job creation. Please try again later.");
        }

        //     console.log("Job created with ID:", response.data.data.jobID);
        //     setJobID(response.data.data.jobID);  // Assuming the API returns a jobID
        //     setJobInterviewLink(b2cUrl+response.data.data.jobID+"_"+interviewRound.value)
        //     handleNextStep();
        // } catch (error) {
        //     console.error("Error submitting job creation data:", error);
        // }

    };

    const handleNextStep = () => {
        setCurrentStep((prevStep) => {
          const nextStep = Math.min(prevStep + 1, stepComponents.length - 1);
          // Only add to completedSteps if we're moving to a new step
          if (nextStep > prevStep && !completedSteps.includes(prevStep)) {
            setCompletedSteps([...completedSteps, prevStep]);
          }
          return nextStep;
        });
        setErrorMessage("");
    };

    const handleBackStep = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
      };
    
      useEffect(() => {
        setCompletedSteps((prevCompleted) => {
          const newCompleted = [...prevCompleted];
          if (newCompleted.indexOf(currentStep) === -1) {
            newCompleted.push(currentStep);
          }
          return newCompleted;
        });
      }, [currentStep]);
    return (  
    <>
        <Box display="flex" justifyContent="center" >
            <Box display="flex" direction="row" width="100%" >
                {currentStep < stepComponents.length - 1 && (
                    <Box width={280} height="100%" >
                        <SideButton 
                            onSelectStep={setCurrentStep} 
                            completedSteps={completedSteps}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        /> 
                    </Box>
                )}
                <Box display="flex" direction="column" width="100%" padding={1} >
                    {stepComponents[currentStep]}
                    {errorMessage && (
                      <Box padding={3}>
                        <Box padding={3}>
                        <Text color="white">{errorMessage}</Text>
                        </Box>
                        <Button
                          text="Upgrade Subscription"
                          color="red"
                          onClick={() => window.location.href = '/premium'}
                        />
                      </Box>
                    )}
                    {currentStep < stepComponents.length - 1 && (
                        <Box display="flex" width="100%" justifyContent="around" alignItems="end" padding={5}>
                            {currentStep > 0 && (
                                <Box width={100}>
                                <Button 
                                    color="gray"
                                    size="lg" 
                                    text="Back"
                                    fullWidth={true}
                                    onClick={handleBackStep}
                                />
                                </Box>
                            )}
                            <Box width={100}>
                            <Button
                                color="blue"
                                size="lg"
                                fullWidth={true}
                                text={currentStep === stepComponents.length - 2 ? "Submit" : "Continue"}
                                onClick={currentStep === stepComponents.length - 2 ? handleSubmit : handleNextStep}
                                disabled={!isFormComplete}
                            />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    </>
        
    ); 
};

export default () => {  
    return ( 
      <>
        <Header isAuthenticated={true}/>   
        <App />  
      </>
    );  
  };  