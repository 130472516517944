import React, { useState, useEffect } from 'react';
import { Box, Button, Text, Icon, Column, Switch, Layer, Modal } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getUser } from "../components/getUser";
import { useUser } from '@clerk/clerk-react';
import { Header } from "../components/Header";
import './Premium.css';

const PricingCard = ({ planName, price, priceSubText,featureStart, features, buttonText, onSubscribe, 
  isCurrentPlan,
  isPremium,
  subscriptionStatus,
  premiumUntil,
  onCancel,
}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    const handleCancelClick = () => {
      setShowConfirm(true);
    };

    const handleConfirmCancel = () => {
      setShowConfirm(false);
      onCancel();
    };

    const handleCancel = () => {
      setShowConfirm(false);
    };

    return (
      <Box 
        padding={3} 
        rounding={4}
        display="flex"
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        borderStyle="raisedTopShadow"
        width="88%"
        height="110%"
      >
        <Text color="shopping" weight="bold" size="400" align = "start">
          {planName}
        </Text>
        <Box height={70}>
        <Box marginTop={3} marginBottom={2}>
        <Text size='500'>
          {price}
        </Text>
        </Box>
        <Text color="subtle" size="100">{priceSubText}</Text>
        </Box>
        <Box marginTop={3} style={{ alignSelf: 'stretch' }} height={35}>
          {/* <Button text={buttonText} color="gray" onClick={onSubscribe} size="sm" width="fullwidth"/> */}
          {isCurrentPlan && isPremium && planName !== 'Basic' ? (
          subscriptionStatus === 'cancellation_pending' ? (
            <Text color="red" size="100">
            Subscription will end on {new Date(premiumUntil).toLocaleDateString()}
          </Text>
          ) : (
            <Button
              text="Cancel Subscription"
              color="red"
              onClick={handleCancelClick}
              size="sm"
              width="fullWidth"
            />
          )
        ) : (
          <Button
            text={buttonText}
            color="blue"
            onClick={onSubscribe}
            size="sm"
            width="fullWidth"
          />
        )}
        </Box>
        <hr style={{ width: '100%', borderColor: 'lightgray', margin: '16px 0' }} />
        <Box marginTop={3} style={{ alignSelf: 'stretch' }}>
          <Text align="left" weight="bold">{featureStart}</Text>
          <Box marginTop={2}marginStart={5}>
            {features.map((feature, index) => (
              <Text key={index} align="left" >
                • {feature}
              </Text>
            ))}
          </Box>
        </Box>
        {showConfirm && (
          <Layer>
            <Modal
              accessibilityModalLabel="Cancel Subscription"
              heading="Confirm Cancellation"
              onDismiss={handleCancel}
              footer={
                <Box display="flex" justifyContent="end">
                  <Button text="No" onClick={handleCancel} />
                  <Button text="Yes, Cancel" color="red" onClick={handleConfirmCancel} />
                </Box>
              }
              size="sm"
            >
              <Text>Are you sure you want to cancel your subscription?</Text>
            </Modal>
          </Layer>
        )}  
      </Box>
    );
  };

const App = () => {
    const [curuser, setCuruser] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentCanceled, setPaymentCanceled] = useState(false);
    const [isAnnual, setIsAnnual] = useState(true);

    const { isSignedIn, user, isLoaded } = useUser();

    // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    // console.log(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
    const stripePromise = loadStripe("pk_live_51OnSVjAfDZyE4r9JzvUnjHRu2CNzlPg79RbS15EE55cs0pj1NY09UVkxkqcHecHSA3fbmsYSSNGDbeU6iTG4Blah00uQQ1eVbp");
    

    // const handleSubscribe = async (plan) => {
    //     const stripe = await stripePromise;
    //     try {
    //         const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/bData/checkout";
    //         const response = await axios.post(apiUrl, {
    //             recruiterID: user.id,
    //             plan: plan,
    //         });
    //         const session = response.data;

    //         const result = await stripe.redirectToCheckout({
    //             sessionId: session.id,
    //         });

    //         if (result.error) {
    //             console.error(result.error.message);
    //         }
    //     } catch (error) {
    //         console.error("Error creating checkout session:", error);
    //     }
    // };

    const handleSubscribe = async (plan) => {
      if (plan === 'Enterprise') {
        window.location.href = 'https://www.mirwork.ai/';
        return;
      }

      if (plan === 'Basic') {
        window.location.href = 'https://evaluation.mirwork.ai/jobpost';
        return;
      }

      // Check if the user has an active subscription
    if (curuser?.subscriptionStatus === 'active' && curuser?.subscriptionLevel !== plan) {
      const confirmCancel = window.confirm('You already have an active subscription. Do you want to cancel your current plan and subscribe to the new one?');

      if (!confirmCancel) {
        return;
      }

      try {
        const cancelApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/bData/cancelSubscription`;
        console.log(process.env.REACT_APP_BACKEND_URL)
        await axios.post(cancelApiUrl, {
          recruiterID: curuser.id,
        });

      alert('Your current subscription has been canceled. Proceeding to the new subscription.');

        // After cancellation, update user state
        let theUser = {emailAddress:user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName}
        let updatedUser = await getUser(isSignedIn, theUser);
        setCuruser(updatedUser);
        // let updatedUser = await getUser();
        // setCuruser(updatedUser);
      } catch (error) {
        console.error('Error canceling subscription:', error);
        alert('There was an error canceling your subscription. Please try again later.');
        return;
      }
    }

      const stripe = await stripePromise;
      const paymentType = isAnnual ? 'yearly' : 'monthly';
  
      try {
          const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/bData/checkout";
          // const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/bData/checkout`;
          const response = await axios.post(apiUrl, {
              recruiterID: curuser.id,
              paymentType: paymentType, // Send the payment type
              plan: plan // Include the selected plan
          });
          const session = response.data;
  
          const result = await stripe.redirectToCheckout({
              sessionId: session.id,
          });
  
          if (result.error) {
              console.error(result.error.message);
          }
      } catch (error) {
          console.error("Error creating checkout session:", error);
      }
  };

  const handleCancelSubscription = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/bData/cancelSubscription`;
      const response = await axios.post(apiUrl, {
        recruiterID: curuser.id,
      });
      // Provide feedback to user
      alert('Your subscription has been canceled and will end at the end of the billing period.');
      // Update user state
      let theUser = {emailAddress:user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName}
      let updatedUser = await getUser(isSignedIn, theUser);
      setCuruser(updatedUser);
      // let updatedUser = await getUser();
      // setCuruser(updatedUser);
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert('There was an error canceling your subscription. Please try again later.');
    }
  };
  

    useEffect(
      // async () => {
      // if (isLoaded){
      // console.log(user)
      //   let theUser = {emailAddress:user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName}
      //   let userInfo = await getUser(isSignedIn, theUser);
      //   setCuruser(userInfo);
      //   console.log(userInfo)
      // }
      () => {
        const fetchUserData = async () => {
            if (isLoaded){
              console.log(user)
              let theUser = {emailAddress:user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName}
              let userInfo = await getUser(isSignedIn, theUser);
              setCuruser(userInfo);
              console.log(userInfo)
            }
        };
        fetchUserData();
    }, [isSignedIn, user, isLoaded]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('success')) {
            setPaymentSuccess(true);
        }
        if (queryParams.get('canceled')) {
          setPaymentCanceled(true);
        }
    }, []);
    
    const togglePlan = () => {
        setIsAnnual(!isAnnual);
    };

    const pricingPlans = [
        {
          planName: 'Basic',
          price: isAnnual ? '2 weeks free trial' : '2 weeks free trial',
          priceSubText: isAnnual ? '  ' : '  ',
          features: [
            'Limit to 1 role',
            'Meeting up to 5 min',
            'Fixed role with default questions',
            'Transcription of call',
          ],
          buttonText: 'Try it now',
          onSubscribe: () => handleSubscribe('Basic')
        },
        {
          planName: 'Pro',
          price: isAnnual ? '$99.92' : '$199',
          priceSubText: isAnnual ? 'per month, billed annually' : 'billed monthly',
          featureStart: 'Everything in Basic, and:',
          features: [
            'Up to 3 roles/month',
            'Meetings up to 15 min',
            'Up to 20 candidates per role',
            'Customized interview questions',
            'Call summary',
            '5Gb Cloud storage',
          ],
          buttonText: 'Buy Now',
          onSubscribe: () => handleSubscribe('Pro')
        },
        {
          planName: 'Business',
          price: isAnnual ? '$416.58' : '$749',
          priceSubText: isAnnual ? 'per month, billed annually' : 'billed monthly',
          featureStart: 'Everything in Pro, and:',
          features: [
            'Up to 10 roles/month',
            'Meetings up to 30 min',
            'Up to 50 candidates per role',
            'Interview insight and evaluation',
            '20GB Cloud storage',
            'Extra SSO',
          ],
          buttonText: 'Buy Now',
          onSubscribe: () => handleSubscribe('Business')
        },
        { 
          planName: 'Enterprise',
          price: 'Contact us',
          priceSubText: '   ',
          featureStart: 'Everything in Business, and:',
          features: [
            'Up to 100 roles',
            'Meetings up to 1 hr',
            'Up to 500 candidates per role',
            'Performance ranking and compatibility matching',
            '1TB Cloud storage +',
            'Extra SSO',
          ],
          buttonText: 'Contact us',
          onSubscribe: () => handleSubscribe('Enterprise')
        },
      ];

    return (
      <Box padding={4}>
        <Box marginBottom={4} display="flex" justifyContent="center">
          <Text size='600'> Sign up today and start interviewing candidates</Text>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" marginBottom={4}>
          <Text size="xs" align="center" marginEnd={3}>Annual</Text>
          <Box marginEnd={3} marginStart={3} color='blue'>
            <Switch
              onChange={togglePlan}
              id="planToggle"
              switched={!isAnnual}
              size="sm"
              color="blue"
              className="customSwitch"
            />
          </Box>
          <Text size="xs" align="center" marginStart={3}>Monthly</Text>
        </Box>
        <Box display="flex" justifyContent="center" marginBottom={6}>
          <Text color={isAnnual ? "success" : "dark"} size="xs" weight="bold">Save up to 50% over monthly plan</Text>
        </Box>
        <Box display="flex" justifyContent="around" wrap>
          {pricingPlans.map((plan, index) => (
            <Column span={3} key={index}>
              <PricingCard 
                {...plan} 
                isCurrentPlan={(curuser?.subscriptionLevel ||
                  'Basic'
                  ) === plan.planName}
                isPremium={curuser?.premium}
                subscriptionStatus={curuser?.subscriptionStatus}
                premiumUntil={curuser?.premiumUntil}
                onCancel={handleCancelSubscription}
              />
            </Column>
          ))}
        </Box>
        {paymentSuccess && (
          <Box color="green" padding={4} rounding={2} display="flex" justifyContent="center" alignItems="center" marginTop={6}>
            <Icon icon="check-circle" accessibilityLabel="Payment successful" color="white" size={12} />
            <Text align="center" color="white" weight="bold">Payment Successful! You are now a Premium Member.</Text>
          </Box>
        )}
        {paymentCanceled && (
          <Box color="red" padding={4} rounding={2} display="flex" justifyContent="center" alignItems="center" marginTop={6}>
            <Icon icon="cancel" accessibilityLabel="Payment canceled" color="white" size={12} />
            <Text align="center" color="white" weight="bold">Payment was canceled. Please try again if you wish to subscribe.</Text>
          </Box>
        )}
      </Box>
    );
};




export default () => {  
    return ( 
      <>
        <Header isAuthenticated={true}/>   
        <App />  
      </>
    );  
  };  
