import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Header } from '../components/Header';
import { useUser } from '@clerk/clerk-react';
import { getUser } from "../components/getUser";
import HrAssistant from '../components/HrAssistant';

const CandidateList = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const [candidates, setCandidates] = useState([]);
  const [suggestedCandidates, setSuggestedCandidates] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const suggestedCandidatesRef = useRef(null);
  const { jobId } = useParams();
  const rootUrl = process.env.REACT_APP_BACKEND_URL;
  const hrAssistantRef = useRef(null);

  // Fetch curUser and userInfo
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (isSignedIn && user) {
        // Set curUser in state
        const curUserData = {
          emailAddress: user.primaryEmailAddress?.emailAddress || '',
          firstName: user.firstName || '',
          lastName: user.lastName || ''
        };

        // Fetch and set userInfo from getUser function
        const userInfoData = await getUser(isSignedIn, curUserData);
        setUserInfo(userInfoData);  // Save userInfo to state
      }
    };

    fetchUserInfo();
  }, [isSignedIn, user]);

  // Fetch candidates list and set in state
  const fetchCandidates = useCallback(async () => {
    if (isLoaded && userInfo) {
      try {
        const response = await axios.post(rootUrl + "/api/bData/applicantList", { recruiterId: userInfo.id, jobID: jobId, userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        setCandidates(response.data.data.applicants || []);
        setJobTitle(response.data.data.jobTitle);

        // Prepare an array of feedbackIds and corresponding full names for hrAssistant
        const candidatesData = response.data.data.applicants.map(applicant => ({
          feedbackId: applicant.FeedbackID,
          fullName: `${applicant.UserFirstName} ${applicant.UserLastName}` // Combine first and last name
        }));
        console.log('Candidates Data: ', candidatesData);

        // Initialize HrAssistant embeddings after candidates are loaded
        if (hrAssistantRef.current) {
          hrAssistantRef.current.initializeEmbedding(candidatesData); // Pass the array of feedbackId and fullName
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          alert("Unauthorized data access!");
        } else {
          console.error('Error fetching candidate list:', error);
        }
      }
    }
  }, [jobId, rootUrl, userInfo, isLoaded]);

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  // Scroll to the center of the suggested candidates list when it's updated
  useEffect(() => {
    if (suggestedCandidates.length > 0 && suggestedCandidatesRef.current) {
      // Ensure the DOM is fully rendered before scrolling
      setTimeout(() => {
        suggestedCandidatesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);  // Delay to allow DOM rendering to complete
    }
  }, [suggestedCandidates]);

  // Function to handle and display suggested candidates based on feedback IDs and annotations
  const handleSuggestedCandidates = (recommendedFeedbackList) => {
    console.log('Recommended Feedback List:', recommendedFeedbackList);

    // Create a new list of suggested candidates in the same order as received from the API
    const suggestedWithAnnotations = recommendedFeedbackList.map(feedback => {
      const candidate = candidates.find(applicant => applicant.FeedbackID === feedback.feedbackId);
      return {
        ...candidate,
        annotation: feedback.annotation, // Add the annotation from feedback
      };
    });

    setSuggestedCandidates(suggestedWithAnnotations); // Set the suggested candidates preserving order
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Candidate List</h1>
      <h4 style={{ textAlign: 'left', margin: '10px' }}>Job Title: {jobTitle}</h4> {/* Display the job title */}
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Name</th>
            <th style={tableHeaderStyle}>Date Applied</th>
            <th style={tableHeaderStyle}>AI Screening Score</th>
            <th style={tableHeaderStyle}>Application Status</th>
            {/* <th style={tableHeaderStyle}>Label</th> */}
          </tr>
        </thead>
        <tbody>
          {candidates.length > 0 ? (
            candidates.map((candidate) => (
              <tr key={candidate.UserID}>
                <td style={tableCellStyle}>
                  <a href={`/feedback/${candidate.FeedbackID}?jobId=${jobId}&name=${candidate.UserFirstName}%20${candidate.UserLastName}`} >
                    {`${candidate.UserFirstName} ${candidate.UserLastName}`}
                  </a>
                </td>
                <td style={tableCellStyle}>{candidate.DateApplied}</td>
                <td style={tableCellStyle}>{candidate.AverageScore.toFixed(1)}/5</td>
                <td style={tableCellStyle}>{candidate.Status}</td>
                {/* <td style={tableCellStyle}></td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center', padding: '10px', marginTop: '50px' }}>
                <div style={{ marginTop: '50px' }}>No candidate applied yet.</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* HrAssistant Component */}
      <div style={{ flex: 1, marginTop: '20px' }}>
        <HrAssistant
          ref={hrAssistantRef}
          handleSuggestedCandidates={handleSuggestedCandidates}
        />
      </div>

      <div ref={suggestedCandidatesRef} style={suggestedCandidatesContainerStyle}>
        {suggestedCandidates.length > 0 && ( // Only show when suggested candidates exist
          <>
            <h2 style={{ fontSize: '18px', color: 'blue' }}>AI Ranking</h2>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>Ranking</th>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Reason for Recommendation</th> {/* Add Annotation Column */}
                </tr>
              </thead>
              <tbody>
                {suggestedCandidates.map((candidate, index) => (
                  <tr key={index}>
                    <td style={{ tableCellStyle, textAlign: 'center' }}>{index + 1}</td>
                    <td style={tableCellStyle}>{`${candidate.UserFirstName} ${candidate.UserLastName}`}</td>
                    <td style={tableCellStyle}>{candidate.annotation}</td> {/* Display Annotation */}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  padding: '10px',
  backgroundColor: '#f2f2f2',
  fontWeight: 'bold',
  textAlign: 'left',
};

const tableCellStyle = {
  padding: '10px',
};

const suggestedCandidatesContainerStyle = {
  marginTop: '40px',
  width: '80%',
  marginLeft: 'auto',
  marginRight: 'auto',
};



const AppWrapper = () => {
  const { isSignedIn } = useUser();
  return (
    <>
      <Header isAuthenticated={isSignedIn} />
      <CandidateList />
    </>
  );
};

export default AppWrapper;

